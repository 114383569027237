<template>
  <div class="row justify-content-center">
    <home-header></home-header>

    <vs-list>
      <vs-list-item class="k-nav-bar" title="ข่าวสาร" subtitle="">
      </vs-list-item>
    </vs-list>
    <div v-if="enable">
      <vs-card :key="indextr" v-for="(item, indextr) in activityItems">
        <div slot="header">
          <h3>
            {{ item.topic }}
          </h3>
        </div>
        <div>
          <span
            style="font-size:16px;white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;"
            >{{ item.desc }}</span
          >
        </div>
        <div
          slot="footer"
          style="padding: 20px;text-align: right;padding-bottom: 40px;"
        >
          <span style="font-size:14px;color:grey;">{{
            new Date(item.create_date.seconds * 1000)
              | moment("dddd, Do MMMM YYYY")
          }}</span>
        </div>
      </vs-card>
        <div>
          <br />
          <paginate
            v-model="page"
            :page-count="total"
            :page-range="3"
            :margin-pages="2"
            :click-handler="inputData"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
    </div>
    <div v-else>
      <the-advertising :post-details="listAdvertising" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import HomeHeader from "@/layouts/components/custom/HomeHeader.vue";
import Paginate from "vuejs-paginate";
import TheAdvertising from "@/components/advertising/TheAdvertising.vue";
import ListAdvertising from "@/store/listAdvertising.js";
console.log("lang", lang);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.component("home-header", HomeHeader);
Vue.component("paginate", Paginate);
Vue.component("the-advertising", TheAdvertising);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment
});

console.log(Vue.moment().locale());
export default {
  data() {
    return {
      deletePrompt: false,
      language: "en",
      languages: lang,
      houseInfo: {},
      activityItems: [],
      activePrompt2: false,
      val: "",
      hNumber: "",
      formModel: {
        dttm: "",
        desc: ""
      },
      highlightedFn: {
        customPredictor(date) {
          const now = moment(new Date()).format("MMMM Do YYYY");
          const d = moment(date).format("MMMM Do YYYY");
          console.log("now=", now);
          console.log("d=", d);
          if (now == d) {
            return true;
          }
        }
      },
      // pagination
      total: 0, // จำนวนหน้า
      limit: 10,
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      lastItem: null,

      user_cloud_config: {},
      enable: null,
      listAdvertising: {}
    };
  },
  computed: {
    validName() {
      console.log(this.formModel);
      return this.formModel.dttm != null && this.formModel.desc.length > 0;
    }
  },
  methods: {
    documentPath() {
      const customerName = $cookies.get("customer");
      const _documentPath = "customer/" + customerName + "/request";
      // console.log("documentPath=" + _ducumentPath);
      return _documentPath;
    },
    getDbWithCondition() {
      return db.collection(this.documentPath())
        .where("type", "==", "news")
    },
    confirmDeleteRecord() {},

    deleteActivity() {
      const customerName = $cookies.get("customer");
      db.collection("customer/" + customerName + "/vip")
        .doc(this.deleteId)
        .delete()
        .then(() => {
          console.log("Document deleted!");
        })
        .catch(error => {
          console.error(error);
        });
    },

    openDeletePromt(id) {
      this.deleteId = id;
      this.deletePrompt = true;
    },

    acceptAlert(color) {
      const customerName = $cookies.get("customer");
      this.hNumber = $cookies.get("home");

      this.formModel.house_no = this.hNumber;
      this.formModel.type = 0;
      this.formModel.subtitle = "";
      this.formModel.title = "";
      this.formModel.activity = "";

      event.preventDefault();
      // db.collection("customer").document(customerName).collection("house")
      db.collection("customer/" + customerName + "/vip/")
        .doc()
        .set(this.formModel)
        .then(() => {
          //alert("Update successfully!");
          // this.user.name = ''
          this.formModel.dttm = null;
          this.formModel.desc = "";

          this.$vs.notify({
            color: "danger",
            title: "",
            text: "บันทึกข้อมูลสำเร็จแล้ว"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    close() {
      this.formModel.dttm = null;
      this.formModel.desc = "";
      // this.$vs.notify({
      //   color:'danger',
      //   title:'Closed',
      //   text:'You close a dialog!'
      // })
    },

    onUpdateForm(event) {
      event.preventDefault();
      db.collection("users")
        .doc(this.$route.params.id)
        .update(this.user)
        .then(() => {
          console.log("User successfully updated!");
          this.$router.push("/list");
        })
        .catch(error => {
          console.log(error);
        });
    },

    deleteUser(id) {
      if (window.confirm("Do you really want to delete?")) {
        const customerName = $cookies.get("customer");

        db.collection("customer/" + customerName + "/vip")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Document deleted!");
          })
          .catch(error => {
            console.error(error);
          });
      }
    },

    async getTotalRow() {
      let totalCustomer = await db
        .collection(this.documentPath())
        .where("type", "==", "news")
        .get()
        .then(document => document.docs.length);
      this.total = Math.ceil(totalCustomer / this.limit);
      this.totalItems = totalCustomer;
    },

    listActivity() {
      this.getTotalRow();
      this.getDbWithCondition()
        .orderBy("create_date", "desc")
        .onSnapshot(snapshotChange => {
          this.activityItems = [];
          snapshotChange.forEach(doc => {
            this.activityItems.push({
              key: doc.id,
              topic: doc.data().topic,
              desc: doc.data().desc,
              create_date: doc.data().create_date
            });
            this.isLoad = false;
          });
        });
    },

    previousData: function() {
      this.fetchHome({ page: this.page - 1, limit: 10});
    },
    nextData: function() {
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    inputData: function(value) {
      this.page = value;
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    fetchHome: async function({ page = 0, limit = 10 }) {
      this.isLoad = true;
      let indexOf = limit * page;
      this.limit = limit;
      this.getDbWithCondition()
        .orderBy("create_date", "desc")
        .get()
        .then(document => {
          this.lastItem = document.docs[indexOf];
          this.getDbWithCondition()
            .startAt(this.lastItem)
            .limit(this.limit)
            .orderBy("create_date", "desc")
            .onSnapshot(snapshotChange => {
            this.activityItems = [];
            snapshotChange.forEach(doc => {
              this.activityItems.push({
                key: doc.id,
                topic: doc.data().topic,
                desc: doc.data().desc,
                create_date: doc.data().create_date
              });
              this.isLoad = false;
            });
        });
      });
    }
  },
  components: {
    Datepicker
  },
  created() {
    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    var parameters = this.$route.query;

    var home = $cookies.get("home");
    // console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    this.hNumber = $cookies.get("home");
    // console.log("this.houseInfo=", this.houseInfo);

    this.listActivity();

    if (
      typeof this.houseInfo.active !== "undefined" ||
      this.houseInfo.active !== null
    ) {
      // console.log("this.houseInfo.active => ", this.houseInfo.active);
      if (this.houseInfo.active === false) {
        this.$vs.notify({
          title: "<center>VCar Park</center>",
          text: "ไม่มี สิทธิ์ใช้งานส่วนเสริม",
          color: "danger",
          position: "top-center"
        });
        setTimeout(() => this.$router.push({ path: "/pages/error-403" }), 2000);
      }
    }

    this.user_cloud_config = $cookies.get("user_cloud_config");
    this.enable = this.user_cloud_config.enable_news;
    this.listAdvertising = ListAdvertising.page_news;
  }
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
</style>
